.MuiDataGrid-menu > div {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px rgba(24, 24, 24, 0.10);
}

.css-6dc8s9-MuiButtonBase-root-MuiMenuItem-root .MuiListItemIcon-root {
    min-width: 24px !important;
}

.gm-control-active,

.gm-fullscreen-control,
.gm-svpc,
.gmnoprint {
  padding-top: 4px !important;
}

.gmnoprint > div {
    margin: 0 1px;
  }

.gmnoprint button,
.gm-style button {
  border-radius: 10px !important;
  padding: 10px !important;
  background-color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: absolute;
}

button[aria-label="Map camera controls"] {
  display: none !important;
}

ul[role="menu"] {
  background-color: rgba(255, 255, 255, 0.9) !important;
  border-radius: 10px !important;
  padding: 5px !important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2) !important;
}

.ssQIHO-checkbox-menu-item {
  border-radius: 10px !important;
  padding: 8px 12px !important;
  background-color: transparent !important;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease-in-out;
}

.ssQIHO-checkbox-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px;
}

.ssQIHO-checkbox-menu-item span {
  margin-right: 8px;
}

.ssQIHO-checkbox-menu-item label {
  cursor: pointer;
  background-color: transparent !important;
  padding: 5px 10px;
  border-radius: 10px;
}