@font-face {
    font-family: 'Gotham';
    src: url(./assets/fonts/Gotham-bold.ttf) format("truetype");
    font-weight: 700;
  }
  
  @font-face {
    font-family: 'Gotham';
    src: url(./assets/fonts/Gotham-medium.ttf) format("truetype");
    font-weight: 500;
  }
  
  @font-face {
    font-family: 'Gotham';
    src: url(./assets/fonts/Gotham-regular.otf) format("opentype");
    font-weight: 400;
  }